.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  height: 5vh;
  background-color: rgb(100, 100, 200); /* Dark blue-gray */
  display: flex;
  align-items: center; /* Vertical centering */
  justify-content: center; /* Horizontal centering */
  padding: 0 20px;
  z-index: 1000;
}

/* Left and Right Sections */
.navbar-center {
  display: flex;
  position: relative;
  align-items: center;
}

/* Navigation Links */
.nav-link {
  color: #ecf0f1; /* Light gray */
  text-decoration: none;
  margin-right: 20px;
  font-size: 1.0rem;
  padding: 0px 12px;
  border-radius: 4px;
  transition: background-color 0.3s, box-shadow 0.3s;
  font-family: "Montserrat";
  font-weight: 600;
  text-align: center;
}

/* Hover Effect */
.nav-link:hover {
  color:  rgb(214, 214, 214);
  
}

/* Active Link Styling */
.active {
  color:  rgb(214, 214, 214);
  font-weight: 800;
}

/* Responsive Design */
@media (max-width: 768px) {
  .nav-link {
    margin-right: 10px;
    font-size: 16px;
  }

  .navbar {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    padding: 10px 20px;
  }

  .navbar-center {
    width: 100%;
    justify-content: space-between;
    margin-bottom: 10px;
  }

}